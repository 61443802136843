<template>
  <path id="Union_13" data-name="Union 13" d="M21.913,62.077H1.03a1.029,1.029,0,0,1,0-2.058H20.884V53.7a1.029,1.029,0,0,1,1.03-1.029H41.769V46.354a1.03,1.03,0,0,1,1.028-1.03H61.942V13.144L43.607,7.661a.961.961,0,0,1,0-1.842L62.347.212A1.029,1.029,0,0,1,64,1.029V46.354a1.03,1.03,0,0,1-1.03,1.029H43.826V53.7A1.03,1.03,0,0,1,42.8,54.73H22.943v6.318a1.029,1.029,0,0,1-1.03,1.03ZM47.243,6.74l14.7,4.395V2.343ZM9.495,56.748A3.6,3.6,0,0,1,7.4,52.106l9.454-32.774H13.324L9.031,26.918A3.69,3.69,0,0,1,4.126,28.28a3.6,3.6,0,0,1-1.36-4.906l5.328-9.413a3.6,3.6,0,0,1,3.132-1.831H23.167a6.974,6.974,0,0,1,5.521,2.74l4.921,6.473,6.442-1.388a3.606,3.606,0,0,1,4.352,3.34,3.713,3.713,0,0,1-2.984,3.733l-8.536,1.836a3.661,3.661,0,0,1-3.622-1.339L26,23.2l-2.93,8.45,7.618.47a1.01,1.01,0,0,1,.243.047,2.53,2.53,0,0,1,1.657,1.876L35.791,45a3.6,3.6,0,0,1-2.234,4.54,3.671,3.671,0,0,1-4.582-2.216l-2.958-7.712-6.276-.13-5.6,15.163a3.595,3.595,0,0,1-4.644,2.1Zm8.643-39.34a.961.961,0,0,1,.923,1.229L9.226,52.714a1.734,1.734,0,0,0,.017,1.353,1.649,1.649,0,0,0,.934.881,1.673,1.673,0,0,0,2.158-.972l5.836-15.8a.871.871,0,0,1,.925-.628l7.609.157a.967.967,0,0,1,.878.619l3.2,8.342a1.7,1.7,0,0,0,2.142,1.058,1.673,1.673,0,0,0,1.034-2.13L30.731,34.568c-.095-.352-.227-.481-.322-.534l-8.73-.54a.966.966,0,0,1-.85-1.276l3.941-11.35a.962.962,0,0,1,1.679-.264L30.8,26.362a1.742,1.742,0,0,0,1.688.62l8.529-1.836a1.778,1.778,0,0,0,1.47-1.748,1.661,1.661,0,0,0-.652-1.246,1.7,1.7,0,0,0-1.382-.314l-7.034,1.516A.951.951,0,0,1,32.447,23l-5.292-6.962a5.04,5.04,0,0,0-3.988-1.978H11.226a1.678,1.678,0,0,0-1.46.852L4.44,24.323a1.678,1.678,0,0,0,.635,2.283,1.71,1.71,0,0,0,2.281-.635l4.57-8.076a.961.961,0,0,1,.838-.487ZM19.871,5.645a5.578,5.578,0,1,1,5.578,5.579A5.585,5.585,0,0,1,19.871,5.645Zm1.926,0a3.652,3.652,0,1,0,3.652-3.652A3.658,3.658,0,0,0,21.8,5.645Z" transform="translate(0.499 0.5)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
</template>

<script>
export default {
name: "IconSuiviProjet"
}
</script>

<style scoped>

</style>